

































































































































































































































































































































































import Vue from 'vue';
import { UserIcon, PlusIcon, Edit3Icon, TrashIcon, Edit2Icon } from 'vue-feather-icons';
import { mapState } from 'vuex';
import RepositoryFactory from '@/services/RepositoryFactory';
import IInvitationRepository from '../../services/Repositories/Invitations/types';
import IUserRepository from '@/services/Repositories/UserRepository/types';
import IMerchantRepository from '@/services/Repositories/merchantRepository/types';
import TokenService from '@/services/TokenService';

const tokenService = new TokenService();

const factory = new RepositoryFactory();

export default Vue.extend({
  name: 'UserManagement',
  data() {
    return {
      regularExpression: '/merchants/([a-zA-Z0-9]{24})/*',
      merchants: [],
      merchantDialog: false,
      editMerchantActive: false,
      notificationUserEmail: '',
      reboxAdminDialog: false,
      reboxAdminFilter: false,
      merchantName: '',
      merchantId: '',
      selectedMerchant: '',
      dialog: false,
      dialogDelete: false,
      invitationPopup: false,
      merchantPopup: false,
      roles: ['ROLE_MERCHANT_ADMIN', 'ROLE_MERCHANT_USER'],
      rules: {
        required: (value: any) => !!value || 'Required.',
        email: (value: string) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
      },
      headers: [
        {
          text: 'Email',
          value: 'email',
          align: 'start',
          sortable: false,
        },
        { text: 'Role', value: 'roles' },
        { text: 'User', value: 'firstName' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      users: [],
      filteredUsers: [],
      createUser: {
        email: '',
        roles: [],
      },
      editedIndex: -1,
      editedItem: {
        firstName: '',
        lastName: '',
        roles: [],
        password: '',
      },
      defaultItem: {
        firstName: '',
        lastName: '',
        roles: [],
        password: '',
      },
    };
  },
  components: {
    UserIcon,
    PlusIcon,
    Edit3Icon,
    TrashIcon,
    DefaultLayout: () => import('@/layouts/Default.vue'),
    FilterDrawer: () => import('@/components/ui/FilterDrawer.vue'),
    SettingsDrawer: () => import('@/components/index/SettingsDrawer.vue'),
    BasePopup: () => import('@/components/ui/BasePopup.vue'),
  },
  computed: {
    ...mapState('globals', ['token']),
    formTitle() {
      return (this as any).editedIndex === -1 ? 'Invite User' : 'Edit User';
    },
    invitationRepository(): IInvitationRepository {
      return factory.get('invitations') as IInvitationRepository;
    },
    userRepository(): IUserRepository {
      return factory.get('users') as IUserRepository;
    },
    merchantRepository(): IMerchantRepository {
      return factory.get('merchant') as IMerchantRepository;
    },
    isReboxAdmin() {
      const roles = tokenService.userRoles(this.token);
      if (roles.includes('ROLE_REBOX_ADMIN')) {
        return true;
      }
      return false;
    },
    usersData() {
      if ((this as any).filteredUsers.length === 0 && (this as any).selectedMerchant) {
        return (this as any).filteredUsers;
      }
      if ((this as any).filteredUsers.length > 0 && (this as any).selectedMerchant) {
        return (this as any).filteredUsers;
      }
      if ((this as any).reboxAdminFilter) {
        return (this as any).filteredUsers;
      }
      return (this as any).users;
    },
    getMerchantEditActiveComputed() {
      return (this as any).editMerchantActive;
    },
  },
  methods: {
    async createInvitation() {
      const roles = tokenService.userRoles(this.token);
      if ((this as any).reboxAdminDialog) {
        (this as any).createUser.roles = ['ROLE_REBOX_ADMIN'];
        const data = await (this as any).invitationRepository.sendInvitation(
          (this as any).createUser,
        );
        if (data.status === 201) {
          (this as any).invitationPopup = true;
        }
        (this as any).reboxAdminDialog = false;
      } else if (roles.includes('ROLE_REBOX_ADMIN')) {
        const data = await (this as any).invitationRepository.sendInvitation(
          (this as any).createUser,
          `/api/merchants/${(this as any).selectedMerchant}`,
        );
        if (data.status === 201) {
          (this as any).invitationPopup = true;
        }
      } else {
        if ((this as any).createUser.roles.includes('ROLE_MERCHANT_ADMIN')) {
          console.log('role_merchant_admin');
          (this as any).createUser.roles.push('ROLE_MERCHANT_USER');
        }
        const data = await (this as any).invitationRepository.sendInvitation(
          (this as any).createUser,
        );
        if (data.status === 201) {
          (this as any).invitationPopup = true;
        }
      }
      (this as any).createUser = {
        email: '',
        roles: [],
      };
    },
    async getData() {
      const data = await (this as any).userRepository.getUsers();
      (this as any).users = data.data['hydra:member'];
      (this as any).selectedMerchant = '';
      (this as any).filteredUsers = [];
      (this as any).reboxAdminFilter = false;
    },
    async getMerchants() {
      const data = await (this as any).merchantRepository.getMerchants();
      (this as any).merchants = data.data['hydra:member'];
    },
    async createMerchant() {
      // eslint-disable-next-line max-len
      const data = await (this as any).merchantRepository.createMerchant({ name: (this as any).merchantName });
      if (data.status === 201) {
        (this as any).merchantPopup = true;
      }
      (this as any).merchantDialog = false;
      await (this as any).getMerchants();
      (this as any).merchantName = '';
    },
    selectMerchant(item: any) {
      (this as any).selectedMerchant = item.id;
      (this as any).filteredUsers = [];
      // eslint-disable-next-line array-callback-return
      (this as any).users.map((singleItem: any) => {
        if (singleItem.merchant) {
          const match = singleItem.merchant.match((this as any).regularExpression);
          if (match) {
            if (match[1] === item.id) {
              (this as any).filteredUsers.push(singleItem);
            }
          }
        }
      });
    },
    async editMerchant(itemId: string) {
      let data;
      if ((this as any).notificationUserEmail !== '') {
        data = await (this as any).merchantRepository.editMerchant({ name: (this as any).merchantName, notificationUser: `/api/users/${(this as any).notificationUserEmail}` }, itemId);
      } else {
        // eslint-disable-next-line max-len
        data = await (this as any).merchantRepository.editMerchant({ name: (this as any).merchantName }, itemId);
      }
      if (data.status === 200) {
        (this as any).merchantPopup = true;
      }
      (this as any).merchantDialog = false;
      await (this as any).getMerchants();
      (this as any).merchantName = '';
      (this as any).notificationUserEmail = '';
    },
    merchantDialogCancel() {
      (this as any).merchantDialog = false;
      (this as any).editMerchantActive = false;
      (this as any).merchantId = '';
    },
    async merchantDialogOn(option: string, itemId: string, itemName: string, item: any) {
      if (option === 'edit') {
        (this as any).merchantDialog = true;
        (this as any).editMerchantActive = true;
        (this as any).merchantName = itemName;
        (this as any).merchantId = itemId;
        (this as any).selectMerchant(item);
      }
      if (option === 'create') {
        (this as any).merchantDialog = true;
        (this as any).editMerchantActive = false;
        (this as any).merchantName = '';
      }
    },
    merchantDialogSave(option: string) {
      if (option === 'edit') {
        (this as any).editMerchant((this as any).merchantId);
      }
      if (option === 'create') {
        (this as any).createMerchant();
        (this as any).merchantId = '';
      }
    },
    showReboxAdmins() {
      (this as any).selectedMerchant = '';
      (this as any).filteredUsers = [];
      // eslint-disable-next-line array-callback-return
      (this as any).users.map((singleItem: any) => {
        if (singleItem.roles.includes('ROLE_REBOX_ADMIN')) {
          (this as any).filteredUsers.push(singleItem);
        }
      });
      (this as any).reboxAdminFilter = true;
    },
    editItem(item: any) {
      (this as any).editedIndex = (this as any).users.indexOf(item);
      (this as any).editedItem = { ...item };
      (this as any).dialog = true;
    },

    deleteItem(item: any) {
      (this as any).editedIndex = (this as any).users.indexOf(item);
      (this as any).editedItem = { ...item };
      (this as any).dialogDelete = true;
    },

    async deleteItemConfirm() {
      await (this as any).userRepository.deleteUser((this as any).editedItem.id);
      (this as any).close();
      (this as any).getData();
    },

    close() {
      (this as any).dialog = false;
      this.$nextTick(() => {
        (this as any).editedItem = { ...(this as any).defaultItem };
        (this as any).editedIndex = -1;
      });
    },

    closeDelete() {
      (this as any).dialogDelete = false;
      this.$nextTick(() => {
        (this as any).editedItem = { ...(this as any).defaultItem };
        (this as any).editedIndex = -1;
      });
    },

    async save() {
      if ((this as any).editedIndex > -1) {
        await (this as any).userRepository.editUser(
          (this as any).editedItem.id,
          (this as any).editedItem,
        );
      } else {
        await (this as any).createInvitation();
      }
      (this as any).close();
      await (this as any).getData();
    },
  },

  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || (this as any).close();
    },
    dialogDelete(val) {
      // eslint-disable-next-line no-unused-expressions
      val || (this as any).closeDelete();
    },
  },

  created() {
    const roles = tokenService.userRoles(this.token);
    if (roles.includes('ROLE_REBOX_ADMIN')) {
      (this as any).getMerchants();
    }
    (this as any).getData();
  },
  mounted() {
    const { filterIcons, icons, actions } = this.$headerService.getIconsAndActions();
    this.$headerService.setDrawerIcon(filterIcons.settings);
    this.$headerService.config({
      title: 'header.admin-panel',
      icons: { icon: icons.processes, action: actions.processes },
    });
  },
  destroyed() {
    const { filterIcons } = this.$headerService.getIconsAndActions();
    this.$headerService.setDrawerIcon(filterIcons.noIcon);
  },
});
